<template>
  <div class="home">
    <div id="image-dark-version-home" :class="['bg-img', 'img', { 'dark-mode-cover-home': dark }]">
      <h1 data-aos="fade-right" data-aos-duration="3000">Content Creation</h1>
      <h2 data-aos="fade-right" data-aos-duration="3000">Visualization</h2>
      <h3 data-aos="fade-right" data-aos-duration="3000">Design and Communication</h3>

    </div>


    <div class="container Multimediedesigner VIKING">
      <div class="VIKING-image">
        <img data-aos="fade-right" data-aos-duration="1000" data-aos-offset="400" class="first-img"
          src="../../assets/Behdin/portræt.jpg" width="100%" height="auto">
      </div>
      <div class="txt h1 h2 first-txt">
        <h3 data-aos="fade-up" data-aos-duration="1000">Siden 2021</h3>
        <h1 data-aos="fade-up" data-aos-duration="1000">VIKING Life-Saving Equipment</h1>

        <p data-aos="fade-up" data-aos-duration="1000">
          Fra februar 2024, lige efter afslutningen af min uddannelse, blev jeg fuldtidsansat hos VIKING Life-Saving i
          kommunikationsafdelingen,
          som er en del af HR-afdelingen. Her har jeg arbejdet tæt sammen med vores Communication Manager og forskellige
          teams og bidraget til en
          bred vifte af kommunikations- og brandingopgaver.
        </p>

        <p>
          I min rolle har jeg haft ansvar for at understøtte Employer Branding-strategien og onboardingprocesser gennem
          udvikling af templates og kreativt
          materiale, såsom design, billeder og video.
        </p>

        <transition name="fade">
          <p v-if="showMoreText" class="more-text" data-aos="fade-up" data-aos-duration="1000">
            Blandt mine opgaver har jeg desuden været med til at udvikle og forbedre virksomhedens
            PowerPoint-skabeloner, så de afspejler VIKING's progression og visuelle identitet.
          </p>
        </transition>
        <p class="see-mere" @click="toggleText">{{ showMoreText ? 'Se mindre' : 'Se mere...' }}</p>

      </div>
    </div>


    <div class="contain first-mode primary-color padding">
      <div class="informations h1">

        <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300" class="info" id="Info-div">
          <div id="FirstIcons" v-if="!dark" class="icons"><img src="../../assets/icons/video-dark.png" width="100px"
              height="auto"></div>
          <div id="FirstIcons" v-else class="icons"><img src="../../assets/icons/video.png" width="100px" height="auto">
          </div>
          <div>
            <h2>Videoer:</h2>
          </div>
          <p>
            I min tid hos VIKING Life-Saving har jeg arbejdet med en bred vifte af videoer, fra e-læringskurser og
            interne
            kommunikationsvideoer til content for sociale medier. Jeg har opnået erfaring med professionelt videoudstyr,
            herunder kamera, lys, gimbal-stabilisering og mikrofoner. Med fokus på formålet og målgruppen har jeg
            udviklet
            videoindhold og redigeret med særligt hensyn til det platform videoen skulle sendes ud på.
          </p>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300" class="info" id="Info-div">
          <div id="FirstIcons" v-if="!dark" class="icons"><img src="../../assets/icons/picture-dark.png" width="80px"
              height="auto"></div>
          <div id="FirstIcons" v-else class="icons"><img src="../../assets/icons/picture.png" width="80px"
              height="auto"></div>
          <div>
            <h2>Fotografering:</h2>
          </div>
          <p>
            Min erfaring med fotografering hos VIKING Life-Saving har især omfattet portrætbilleder samt situations- og
            stemningsbilleder fra events, store møder og forsamlinger. Målet har været at skabe materiale til både
            intern
            og ekstern brug. Jeg har opnået kompetence i brugen af professionelt kameraudstyr og arbejdet med
            lysopsætninger,
            hvilket har gjort mig i stand til at fange den rette stemning og autenticitet i hvert billede.
          </p>
        </div>

      </div>

      <!-- <div class="btn-box justify-center" id="facts-btn">
        <router-link to="/Uddannelse"><button class="btn">Se mere</button></router-link>
      </div> -->

    </div>

    <div class="container min-concept flex-direction second-mode">
      <div data-aos="fade-right" data-aos-duration="2000" data-aos-offset="300" class="txt h1 h2 txt-box">
        <div>
          <h3>Min Erfaring med video arbejde hos VIKING Life-Saving</h3>
          <h1>Video - Visuel Kommunikation</h1>
          <p>
            Hos VIKING har jeg haft ansvaret for at planlægge, optage og udarbejde videoer,
            der dokumenterede vigtige forsamlinger og strategiske møder med fokus på intern
            kommunikation. Videoerne bestod af interviews, b-rolls og generelle klip fra
            arrangementerne, som tilsammen skabte en dybdegående og sammenhængende præsentation
            af mødernes indhold og formål.
          </p>
          <p>
            Disse videoer havde til formål at formidle strategier
            på en visuelt engagerende måde, så hver medarbejder kunne forstå sin egen rolle
            og bidrag til de overordnede mål.
          </p>

          <transition name="fade">
            <p v-if="showMoreTextSecond" class="more-text" data-aos="fade-up" data-aos-duration="1000">
              Derudover har jeg optaget videoer og billeder fra VIKING's forskellige afdelinger,
              som bidrog til virksomhedens employer branding-strategi. Med denne indsats skabte
              jeg indhold, der styrkede VIKING’s position som en attraktiv arbejdsplads og gav en
              autentisk indsigt i hverdagen og værdierne på tværs af organisationen.
            </p>
          </transition>
          <p class="see-mere" @click="toggleTextSecond">{{ showMoreTextSecond ? 'Se mindre' : 'Se mere...' }}</p>

        </div>

      </div>

      <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300" class="gyldne-trekant image"><img
          src="../../assets/Behdin/Viking-camera.jpg" width="100%" height="auto"></div>
    </div>

    <div class="contain first-mode">
      <div class="informations h1">
        <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300" class="info">
          <img v-if="!dark" src="../../assets/procent.png" width="150px" height="auto">
          <img v-else src="../../assets/procent dark-version.png" width="150px" height="auto">
          <p>Mennesker husker 65% af visuelle informationer efter tre dage, sammenlignet med kun 10% af de hørte.</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300" class="info">
          <img v-if="!dark" src="../../assets/Video.png" width="150px" height="auto">
          <img v-else src="../../assets/Video-dark-version.png" width="150px" height="auto">
          <p>Virksomheder, der bruger video i deres interne kommunikation, oplever en 75% stigning i
            medarbejderengagement.</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300" class="info">
          <img v-if="!dark" src="../../assets/SoMe.png" width="150px" height="auto">
          <img v-else src="../../assets/SoMe dark-version.png" width="150px" height="auto">
          <p>Visuelle elementer gør information op til 80% mere mindeværdig sammenlignet med tekst.</p>
        </div>
      </div>
    </div>



    <div class="container Multimediedesigner VIKING">
      <div class="VIKING-image">
        <img data-aos="fade-right" data-aos-duration="1000" data-aos-offset="400" class="first-img"
          src="../../assets/Artworks/Powerpoint-slide.jpg" width="100%" height="auto">
      </div>
      <div class="txt h1 h2 first-txt">
        <h3 data-aos="fade-up" data-aos-duration="1000">Præsentationsdesign og Struktur</h3>
        <h1 data-aos="fade-up" data-aos-duration="1000">Fra Information til Visualisering</h1>

        <p data-aos="fade-up" data-aos-duration="1000">
          Jeg har opnået erfaring med at udvikle og forbedre virksomhedens PowerPoint-skabeloner, så de præcist
          afspejler
          VIKING’s visuelle identitet og kommunikationsmål. I disse projekter har jeg arbejdet tæt sammen med interne
          teams
          for at omdanne komplekse informationer og strategier til klare, visuelt engagerende formater, der er målrettet
          specifikke målgrupper.
        </p>

        <p>
          Mit fokus har været at skabe præsentationer, som er både informative og æstetisk tiltalende,
          så budskaberne formidles på en måde, der gør dem lette at forstå og huske.
        </p>

        <transition name="fade">
          <p v-if="showMoreText" class="more-text" data-aos="fade-up" data-aos-duration="1000">
            En vigtig del af mit arbejde har været at udvikle illustrationer, opbygge skabeloner og strukturere billeder
            for at understøtte
            det visuelle indhold. Ved at kombinere PowerPoint og diverse Adobe-værktøjer har jeg sikret en
            sammenhængende og professionel
            præsentationsstil, som effektivt når ud til målgruppen.
          </p>
        </transition>
        <p class="see-mere" @click="toggleText">{{ showMoreText ? 'Se mindre' : 'Se mere...' }}</p>

      </div>
    </div>

    <div class=" om-mig first-mode">
      <div data-aos="fade-right" data-aos-duration="2000" data-aos-offset="300" class="bussines-card"><img
          src="../../assets/Artworks/large/visitcard (artwork).jpg" width="100%" height="100%"></div>
      <div class="displayFlex">
        <div class="om-mig-txt h1 h2 first-mode">
          <h1 data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300">Behdin Bagheri</h1>

          <p data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300">
            Siden 2009 har jeg haft fornøjelsen af at bo i Danmark, hvor jeg har udviklet mig både
            personligt og fagligt. Jeg er nu 24 år og har siden jeg startede min uddannelse arbejdet
            målrettet på at opbygge en karriere inden for visuel kommunikation og grafisk design.
          </p>
          <p data-aos="fade-up" data-aos-duration="2000" data-aos-offset="300">
            Efter at have afsluttet multimediedesigner uddannelsen besluttede jeg at anvende mine
            færdigheder i erhvervslivet, hvor jeg blev ansat hos VIKING Life-Saving, samtidig med
            at jeg studerede videre som webudvikler.
          </p>
        </div>
      </div>
    </div>




  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { ref, onMounted } from 'vue';


const showMoreText = ref(false);
const showMoreTextSecond = ref(false); // Ny tilstand for den anden tekst
const isScreenLarge = ref(window.innerWidth >= 1800);

const toggleText = () => {
  showMoreText.value = !showMoreText.value;
};

const toggleTextSecond = () => { // Funktion til den anden tekst
  showMoreTextSecond.value = !showMoreTextSecond.value;
};


const handleResize = () => {
  isScreenLarge.value = window.innerWidth >= 1800;
  if (isScreenLarge.value) {
    showMoreText.value = true;
    showMoreTextSecond.value = true; // Sikre begge tekster vises på store skærme
  } else {
    showMoreText.value = false;
    showMoreTextSecond.value = false;
  }
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize(); // Initial check
});

defineProps({
  dark: {},
});

</script>






<style lang="scss">
@import '../css/style.scss';


.icons {
  width: 20%;
  height: auto;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
  }
}



.home {
  .bg-img {
    background-position: right !important;
  }
}

#Info-div {
  width: 35vw;
  text-align: left !important;

  h2 {
    color: var(--title-color) !important;
  }
}

.more-text {
  transition: opacity 0.5s ease;
}

.see-mere {
  cursor: pointer;
}

.see-mere:hover {
  opacity: 0.5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* End state of leave */
  {
  opacity: 0;
}

.txt-box {
  width: 50%;
  margin-right: 5%;
}

.VIKING-image {
  width: 50vw;

  .first-img {
    width: 100% !important;
  }
}

.primary-color {
  background: var(--primary-color) !important;
}

.Multimediedesigner {
  .txt {
    width: 55%;
  }
}



@media only screen and (min-width: 1850px) {
  .container {
    h1 {
      font-size: 55px !important;
    }
  }
}

/* 4K Monitor */
@media only screen and (min-width: 1640px) {
  .img {
    background-image: url('@/assets/startimg.png');

    h1 {
      font-size: 70px !important;
    }

    h2 {
      font-size: 70px !important;
    }

    h3 {
      font-size: 30px !important;
    }
  }


  .container {
    h1 {
      font-size: 45px;
    }
  }
}

.dark-mode-cover-home {
  background-image: url('@/assets/startpage dark mode.png') !important;
}

.img {
  background-image: url('@/assets/startimg.png');

  h1 {
    padding: 17vw 0 0 12%;
    margin: 0;
    font-size: 55px;
    text-align: left;
  }

  h2 {
    padding: 0 0 0 12%;
    font-size: 55px;
    color: var(--tertiary-color);
  }

  h3 {
    color: var(--tertiary-color);
    font-size: 22px;
    padding: 5px 0 0 12%;
  }
}

.VIKING {
  background: var(--secondary-color) !important;
}

.Multimediedesigner {
  position: relative;
  background: var(--primary-color);
  padding-top: 110px;
  padding: 100px 8% 70px 8%;


  .ui {
    margin-right: 5%;
  }

  .webmock {
    .first-img {
      width: 28vw;
      height: auto;
    }
  }

  .txt {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    margin-left: 5%;
  }

  .ui-ux {
    display: flex;
    flex-direction: column;

    img {
      margin-top: 85px;
    }
  }
}

.min-concept {
  padding: 110px 8% 70px 8%;
  background: var(--secondary-color);

  h1 {
    margin-bottom: 30px;
  }

  .gyldne-trekant {
    width: 40vw;
  }

  .btn {
    margin-top: 50px;
  }
}

.txt-two {
  margin: 30px 0 0 0;
}

.contain {
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  align-items: center;

  .btn {
    margin-bottom: 80px;
  }

  .informations {
    display: flex;
    justify-content: center;

    .info {
      width: 15vw;
      margin: 80px 100px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      img {
        width: 100%;
        max-width: 250px;
      }
    }
  }
}

.website {
  padding: 110px 8% 70px 8%;
  background: var(--secondary-color);

  .btn {
    margin-top: 177px;
  }

  h1 {
    margin-bottom: 30px;
  }

  .code-lang {
    width: 25vw;
  }
}


.om-mig {
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  background: var(--primary-color);
  padding: 50px 8% 50px 8%;

  .om-mig-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20%;

    h1 {
      margin-bottom: 30px;
    }
  }

  .bussines-card {
    height: 100%;

    img {
      width: 42vw;
    }
  }
}

.displayFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 2000px) {
  .webmock {
    .first-img {
      width: 24vw !important;
    }

    .second-img {
      width: 73%;
    }
  }
}

@media only screen and (max-width: 1380px) {
  .bg-img {
    h1 {
      padding-top: 19vw;
      font-size: 45px !important;
    }

    h2 {
      font-size: 45px !important;
    }

    h3 {
      font-size: 20px !important;
    }
  }

  .info {
    margin: 80px 5vw !important;
  }
}

@media only screen and (max-width: 1200px) {
  .bg-img {
    h1 {
      padding-top: 22vw;
    }
  }

}

@media only screen and (min-width: 1900px) {
  .padding{
    padding: 50px 0;
  }
}

@media only screen and (max-width: 1120px) {
  .bg-img {
    h1 {
      padding: 25vw 0 0 8%;
      font-size: 35px !important;
    }

    h2 {
      padding-left: 8%;
      font-size: 35px !important;
    }

    h3 {
      padding-left: 8%;
      font-size: 18px !important;
    }
  }

  .Multimediedesigner {
    .txt {
      margin: 0 !important;
      width: auto !important;
    }
  }

  .contain .informations {
    flex-direction: column !important;
  }

  .VIKING-image {
    width: 65vw;
  }

  #Info-div {
    width: auto !important;
    text-align: left !important;
    padding: 70px 8% 50px 8%;
    margin: 0 !important;
  }

  .contain .informations .info {
    width: 50vw;
    margin: 30px 5vw !important;
  }

  .btn {
    margin-top: 20px !important;
  }

  .container {
    flex-direction: column-reverse !important;
  }

  .flex-direction {
    flex-direction: column !important;
  }

  .webmock {
    .second-img {
      display: flex !important;
      width: 90%;
      margin: 50px 0 0 0;
    }
  }

  .ui-ux {
    img {
      margin-top: 40px !important;
    }
  }

  .Multimediedesigner {
    padding: 70px 8% 50px 8%;
  }

  #uix {
    flex-direction: row;
    justify-content: space-between;
  }

  .om-mig {
    padding: 0 !important;
    flex-direction: column;

    .om-mig-txt {
      padding: 100px 7% !important;
      width: 85%;
      margin-right: 0 !important;

      h1 {
        padding: 0 !important;
      }
    }
  }

  #facts-btn {
    align-items: center;
    justify-content: center;
  }

  .txt-box {
    display: flex;
    flex-direction: column;
    width: auto !important;
  }

  .bussines-card {
    height: 100%;

    img {
      width: 100% !important;
    }
  }



  .gyldne-trekant {
    width: 65vw !important;
  }

  .code-lang {
    margin: 20px 0 50px 0 !important;
    width: 65vw !important;
    height: 80%;
    overflow: hidden;
    position: relative;
  }


  .informations {
    flex-direction: row;

    .info {
      img {
        width: 45%;
      }
    }
  }
}






.h1 {

  h1,
  h3 {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 1020px) {
  h1 {
    padding-left: 5% !important;
  }

  h2 {
    padding-left: 5% !important;
  }

  h3 {
    padding-left: 5% !important;
  }
}


@media only screen and (max-width: 940px) {
  .bg-img {
    h1 {
      padding-top: 28vw;
    }
  }

  .image {
    width: 100% !important;
  }

  .info[data-v-48676f02] {
    margin: 40px 5vw 0 5vw !important;
  }

  .om-mig-txt {
    width: auto !important;
  }
}

@media only screen and (max-width: 780px) {
  .img {
    background-image: url('@/assets/startimg(small-version).png') !important;
  }

  .dark-mode-cover-home {
    background-image: url('@/assets/startimg(small-version) dark-version.png') !important;
  }

  #facts-btn {
    justify-content: center !important;
  }

  .image {
    margin: 20px 0 0 0 !important;
  }

  .website {
    padding: 50px 5% 50px 5%;
  }

  .flex-direction {
    margin: 0;
  }

  .txt-box {
    width: auto !important;
  }

  .informations {
    flex-direction: row;

    .info {
      margin: 80px 2vw !important;

      img {
        width: 70%;
      }
    }
  }

}

@media only screen and (max-width: 660px) {
  .bg-img {
    h1 {
      padding-top: 23vw;
    }
  }


  .VIKING {
    padding: 70px 5% 60px 5% !important;
  }

  .VIKING-image {
    width: 87vw !important;

  }

  #Info-div {
    padding: 70px 0 0 0 !important;
  }

  #Info-div:first-child {
    padding-top: 0 !important;
  }

  .container {
    justify-content: left;

    .ui {
      margin-right: 5%;
    }
  }

  .om-mig .om-mig-txt {
    padding: 50px 7% !important;
  }

  .Multimediedesigner[data-v-48676f02] {
    padding: 100px 5% 20px 5%;
  }

  .min-concept {
    padding: 50px 5% 70px 5% !important;
  }

  .image[data-v-48676f02] {
    margin: 20px 0 50px 0 !important;
  }

  .first-mode {
    padding: 50px 5% 50px 5%;
  }

  .image[data-v-48676f02] {
    width: 100% !important;
  }

  .website[data-v-48676f02][data-v-48676f02] {
    padding: 50px 6% 0 6% !important;
  }

  .om-mig-txt[data-v-48676f02] {
    padding: 50px 7% !important;
  }

  .min-concept[data-v-48676f02] {
    padding: 50px 5% 20px 5%;
  }

  .website .btn[data-v-48676f02] {
    margin-top: 20px !important;
  }

  .website[data-v-48676f02] {
    padding: 110px 6% 0 6% !important;
  }

  .concept-txt-box {
    flex-direction: column !important;
  }

  .btn-box {
    justify-content: flex-start;
  }

  .informations {
    flex-direction: column;

    .info {
      margin: 20px auto !important;
    }
  }
}

@media only screen and (max-width: 580px) {
  #FirstIcons {
    width: 35% !important;
  }
}

@media only screen and (max-width: 500px) {
  h1 {
    padding: 29vw 0 0 5% !important;
  }

  h2 {
    padding-left: 5% !important;
  }

  h3 {
    padding-left: 5% !important;
  }

  #uix {
    flex-direction: column;
  }

  .webmock {
    .second-img {
      width: 100%;
    }
  }

  .informations:first-child {
    margin-top: 0 !important;
  }

  .informations .info img {
    width: 90%;
  }
}

@media only screen and (max-width: 420px) {
  .img {
    background-image: url('@/assets/startimg(extrasmall-version).png') !important;
  }

  .dark-mode-cover-home {
    background-image: url('@/assets/startimg(extrasmall-version) dark-version.png') !important;
  }

  .bg-img {
    height: 600px !important;

    h1 {
      padding-top: 40vw !important;
    }
  }

  .min-concept .btn[data-v-48676f02] {
    margin-top: 20px !important;
  }

  .ux {
    align-items: flex-start !important;
  }

}

@media only screen and (max-width: 378px) {
  .bg-img {
    h1 {
      padding: 46vw 0 0 5% !important;
    }

    h2 {
      padding-left: 5% !important;
      font-size: 25px !important;
    }

    h3 {
      padding-left: 5% !important;
    }
  }

  .txt {
    width: 90vw !important;
  }

  .txt-two {
    width: 90vw;
  }
}
</style>
